<template>
  <div class="conditionNeeds flex f-wrap" v-if="!REMOVED">
    <span v-if="space_type" class="tag" :class="space_type">
      {{ $tc(`condNeed.${space_type}`, 2) }}
    </span>

    <span v-for="{ key } in itemsActive" :key="key" class="tag" :class="key">
      {{ $tc(`condNeed.${key}`, 2) }}
    </span>
    <span v-if="facility_type" class="tag" :class="facility_type">
      {{ $t(`facility.${facility_type}`) }}
    </span>
  </div>
</template>
<script>
export default {
  data() {
    return { REMOVED: true };
  },
  computed: {
    facility_type() {
      return this.participant.participant_facility_type;
    },
    space_type() {
      return this.participant.participant_space_type;
    },
    items() {
      const P = this.participant;
      return [
        // { key: 'AMB', value: P.participant_is_amb },
        { key: 'blind', value: P.participant_is_blind },
        { key: 'deaf', value: P.participant_is_deaf },
        { key: 'cbla', value: P.participant_cbla },
        { key: 'mental', value: P.participant_mentalillness },
        { key: 'companion', value: P.participant_needs_companion },
        { key: 'SFFD', value: P.participant_safe_for_female },
        // { key: 'SFMD', value: P.participant_safe_for_male },
        { key: 'NAW', value: P.participant_needs_walker },
        { key: 'NVR', value: P.participant_needs_vehicle_ramp },
        { key: 'trans', value: P.participant_needs_translator }
        // { key: 'HIWCH', value: P.participant_high_raise_vehicle },
        // { key: 'WCH', value: P.participant_needs_wheelchair },
      ];
    },
    itemsActive() {
      return this.items.filter(({ value }) => value);
    }
  },
  props: {
    participant: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="sass" scoped>
.tag
  margin: 0 10px 10px 0
  padding: 10px 15px
  border-radius: $br-md
  font-weight: bold
  height: 20px
  text-transform: uppercase
  color: black
.AMB,.deaf,.cbla,.companion,.SFFD,.HIWCH,.WCH,.WCT,.trans,.ALF,.SNF
  color: white
.AMB
  background: #498519
.ALF,.SNF
  background: #A9314D
.deaf
  background: #0D8187
.cbla
  background: #4F388F
.mental
  background: #FFAD5F
.companion
  background: #9F6B01
.SFFD
  background: #5371C5
.NAW
  background: #F88FCF
.NVR
  background: #EED54A
.HIWCH
  background: #8C445A
.WCH
  background: #8A6130
.WCT
  background: #8F3877
.blind
  background: orange
.trans
  background: green
</style>
