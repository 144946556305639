<template>
  <PastServices>
    <PastServiceCard
      :title="$t('pastServices.monthlyTrips')"
      icon="car-hatchback"
      theme="success"
      text="15 trips"
      subText="5 this month"
    />
    <PastServiceCard
      icon="close-circle"
      theme="danger"
      :title="$t('pastServices.cancelTrips')"
      text="3 times only"
      subText="Lorem ipsum"
    />
    <PastServiceCard
      icon="star"
      theme="warning"
      :title="$t('pastServices.effectiveness')"
      text="15%"
      subText="Lorem ipsum"
    />
    <PastServiceCard
      :title="$t('pastServices.changesHistory')"
      icon="package-down"
      :modalOptions="{ title: 'Modal title' }"
      :loading="history.loading"
      :text="history.total"
      @click="isModalOpen = true"
    >
      <template #modal-body>
        <div v-for="log in history.data" :key="log.id" class="table-row">
          <div class="tl-head">
            <div>
              <strong>{{ $t('global.userName') }}:</strong> {{ log.user_name }}
            </div>
            <div>
              <strong>{{ $t('pastServices.action') }}:</strong> {{ log.action_type }}
            </div>
          </div>
          <div class="tl-body flex f-full">
            <div v-if="log.old_data" class="tl-compare">
              <div class="tl-title">{{ $t('pastServices.oldData') }}</div>
              <div v-for="key in Object.keys(log.old_data)" :key="key">
                <strong>{{ key }}:</strong> {{ log.old_data[key] }}
              </div>
            </div>
            <div v-if="log.new_data" class="tl-compare">
              <div class="tl-title">{{ $t('pastServices.newData') }}</div>
              <div v-for="key in Object.keys(log.new_data)" :key="key">
                <strong>{{ key }}:</strong> {{ log.new_data[key] }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </PastServiceCard>
  </PastServices>
</template>

<script>
import { PastServices, PastServiceCard } from '@/components';
import { Permissions } from '@/utils/Secure';
export default {
  components: {
    PastServices,
    PastServiceCard
  },
  mounted() {
    this.getParticipantLog();
  },
  computed: {
    hasPermission() {
      return this.Secure.permissionValidator(Permissions.ActivityLogger.read);
    }
  },
  data() {
    return {
      history: { loading: false, total: 0, data: [] },
      isModalOpen: false
    };
  },
  watch: {
    participant() {
      this.getParticipantLog();
    }
  },
  methods: {
    getParticipantLog() {
      if (this.participant.id && this.hasPermission) {
        this.getActivityLog();
        this.getActivityLogTotalItems();
      }
    },
    async getActivityLog() {
      try {
        const { data } = await this.Api.get(`activity_logger/participants/${this.participant.id}`);
        data.map((d) => {
          d.old_data = JSON.parse(d.old_data || null);
          d.new_data = JSON.parse(d.new_data || null);
        });
        this.history.data = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getActivityLogTotalItems() {
      this.history.loading = true;
      try {
        const { data } = await this.Api.get(
          `activity_logger/participants/${this.participant.id}/total_items`
        );
        this.history.total = data.total_items;
      } catch (error) {
        console.error(error);
      }
      this.history.loading = false;
    }
  },
  props: {
    participant: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="sass" scoped>
.pastServices
  margin: 0!important
  padding: 0!important
  ::v-deep
    .card-content
      padding: 15px 0 0!important
      margin: 0 -5px!important
$text-color: darken($main-separators, 5)
$monthly-trips-color: darken(#B8E396, 7)
$cancel-trips-color: $red
$effectiveness-color: $yellow
$dark-color: $dark
.table-row
  border-bottom: 1px solid $main-separators
  padding-bottom: 10px
  margin-bottom: 10px
  .tl-body
    padding: 0 20px
  .tl-head
    padding: 0 10px
  .tl-title
    background: $main-background
    margin-left: -5px
    padding: 2px 5px
  .tl-compare
    width: 100%
</style>
