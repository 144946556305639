<template>
  <section>
    <Portal to="headerActions">
      <Link to="/data/participants/add" isRouter :permission="Permission.Participants.create">
        <b-button
          type="is-primary"
          :label="$t('button.add', [$tc('participant.label').toLowerCase()])"
          icon-left="plus"
        />
      </Link>
    </Portal>
    <b-loading :active="loading"></b-loading>
    <div class="participants columns">
      <Base-searcher
        class="column"
        :api="searchApiUrl"
        @updated="onSearcherUpdate"
        queryKey="all"
        :filterOptions="filterOptions"
        :dataPreProcesor="participantsDataPreProcesor"
        ref="Searcher"
      >
        <template #default="{ data }">
          <Searcher-card
            v-for="item in data"
            :key="`${item.id}${item.participant_address_id || `p${item.id}`}`"
            :active="participant == item"
            :participant="item"
            @click="onSelect"
          />
        </template>
      </Base-searcher>
      <div class="rigth column">
        <Card :participant="participant" @onDelete="onDelete" />
      </div>
    </div>
  </section>
</template>
<script>
import BaseSearcher from '@/components/BaseSearcher/Searcher.vue';
import Card from '@/components/Participants/Card';
import SearcherCard from '@/components/Participants/SearcherCard.vue';
import { Link } from '@/components';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    BaseSearcher,
    Card,
    Link,
    SearcherCard
  },
  data() {
    return {
      loading: false,
      participant: {}
    };
  },
  computed: {
    filterOptions() {
      return {
        label: this.$t('global.fundingSrc'),
        api: 'funding_sources?per_page=9999&is_active=1',
        params: ['is_active=2', 'participant_address_is_default=1'],
        data: [],
        field: 'name',
        model: 'participant_funding_source_id',
        permission: this.Permission.FundingSource.readAll
      };
    },
    Permission() {
      const { Participants, FundingSource } = Permissions;
      return { Participants, FundingSource };
    }
  },
  methods: {
    searchApiUrl(urlParams) {
      const hasAllParam = !!urlParams?.find((urlParam) => urlParam.indexOf('all=') >= 0);
      return `participants${hasAllParam ? '/search' : ''}`;
    },
    participantsDataPreProcesor(participants) {
      if (participants[0]?._source) {
        return participants.map(({ highlight, _source }) => ({ ..._source, highlight }));
      }
      return participants;
    },
    onSearcherUpdate({ page, items }) {
      if (page == 1) this.participant = items[0] || {};
    },
    onSelect(participant) {
      this.participant = participant;
    },
    onDelete(id) {
      const item = this.$refs.Searcher.onRemoveById(id);
      this.participant = item || {};
    }
  }
};
</script>
<style lang="sass" scoped>
.participants
  height: calc(100% - 75px)
  .columns
    width: 100%
    .column
      width: 50%
  @media screen and ( min-width: $bp-xxl )
    .column
      @include static-width(45%)
    .rigth
      @include static-width(55%)
.searcher
  max-width: 650px
.rigth
  display: flex
  flex-flow: column
  justify-content: space-between
  padding-bottom: 0
</style>
