<template>
  <Card class="searchCard p-card-il" :class="active ? 'active' : ''" @click="onCardClick">
    <div class="picture-container" :class="disabled">
      <div class="picture">
        <Picture
          :src.sync="participant.pictureSrc"
          :preasigned="{
            apiUrl: '/presigned/participant',
            fileName: `${participant.id}.png`
          }"
          :alt="participant.participant_full_name"
        />
      </div>
    </div>
    <div class="details" :class="disabled">
      <div class="flex header">
        <strong>{{ participant.participant_full_name }}</strong>
        <span>{{ participant.participant_client_code }}</span>
      </div>
      <p>{{ full_address }}</p>
      <Conditions-needs class="flex" :participant="participant" />
      <ElasticSearchHighlightIcons
        v-if="participant.highlight"
        :highlights="participant.highlight"
        :disabled="!participant.participant_is_active"
      />
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card.vue';
import ConditionsNeeds from '@/components/Participants/ConditionsNeeds.vue';
import { Picture, ElasticSearchHighlightIcons } from '@/components';

export default {
  components: {
    Card,
    ConditionsNeeds,
    ElasticSearchHighlightIcons,
    Picture
  },
  computed: {
    full_address() {
      const address = this.participant.participant_address;
      if (typeof address === 'string') return this.participant?.participant_address_full_address;

      const data = this.participant.participant_address || [];
      return data?.find((a) => a.is_default)?.full_address || data[0]?.full_address;
    },
    disabled() {
      return this.participant.participant_is_active ? '' : 'is-disabled';
    }
  },
  methods: {
    onCardClick() {
      this.$emit('click', this.participant);
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    participant: {
      type: Object,
      default: () => {}
    },
    highlights: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="sass" scoped>
$pictureSize: 72px
.p-card-il
  ::v-deep
    .card-content
      padding: 0
      display: flex
.picture-container
  justify-content: center
  min-width: $pictureSize
  width: $pictureSize
  display: flex
  flex-flow: column
  margin-right: 25px
  .rate
    font-size: 12px
    margin: 0 auto
  .picture
    height: $pictureSize
    margin-bottom: 10px
    border-radius: $br-md
    overflow: hidden
    .b-image-wrapper
      margin: 0
.details
  width: 100%
  .header
    justify-content: space-between
    strong
      font-size: $f-lg
  p
    color: $gray-700
    margin-bottom: 5px
</style>
